<template>
  <div>
    <div class="add">
      <el-button @click="add('new', '')" size="small" style="margin-right: 10px;">添加</el-button>
      <!-- 请输入科室：  -->
      <el-input
        placeholder="请输入科室"
        clearable
        prefix-icon="el-icon-search"
        v-model="search"
        style="width: 200px;"
        @clear="searchclear"
         size="small"
      >
      </el-input>
      <el-button type="primary" size="small" style="margin-left:10px" @click="query">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column prop="name" label="科室"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <div class="opt">
            <el-button size="mini" type="primary" icon="el-icon-edit" @click="add('bj', scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="del(scope.row, scope.$index)"
              >删除</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <p class="paging">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="current"
                :page-sizes="pageSizes" :page-size="PageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount" :hide-on-single-page="value"></el-pagination>
        </p> -->
    <el-dialog :title="title" :visible.sync="dialogFormVisible" width="50%">
      <el-form :model="form" class="hdtc" label-width="170px">
        <el-form-item label="科室">
          <el-input
            style="float: left;"
            v-model="form.name"
            autocomplete="off"
            placeholder="请输入科室"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="科室图片">
          <upload :src.sync="form.pic"></upload>
        </el-form-item>
        <el-form-item label="排序">
          <el-input
            style="float: left;"
            type="number"
            v-model="form.sort"
            autocomplete="off"
            placeholder="请输入数字"
          >
          </el-input>
          <span style="float: left;color: #909399;">数字越大排名越靠前</span>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            form = {};
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="sub">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import upload from "../components/upload";
export default {
  components: {
    upload,
  },
  inject: ["reload"],
  data() {
    return {
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      form: {
        name: "",
        pic: "",
        sort: "",
      },
      dialogFormVisible: false,
      title: "添加科室",
      type: "",
      item: "",
      search: "",
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.axios
        .get("/gu/get_gu_department")
        .then((rs) => {
          console.log(rs.data);
          if (rs.data.code == 0) {
            this.list = rs.data.result;
          } else {
            console.log(rs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    add(type, item) {
      // console.log(item);
      this.type = type;
      this.item = item;
      this.dialogFormVisible = true;
      if (type != "new") {
        this.title = "编辑科室";
        this.form.name = item.name;
        this.form.pic = item.pic;
        this.form.sort = item.sort;
      } else {
        this.title = "添加科室";
        this.form.name = "";
        this.form.pic = "";
        this.form.sort = "";
      }
    },
    del(row, index) {
      this.$confirm("此操作将永久删除该科室, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios
            .post("/gu/delete_gu_department", this.qs.stringify({ id: row.id }))
            .then((rs) => {
              if (rs.data.code == 0) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
                this.list.splice(index, 1);
              } else {
                this.$message(rs.data.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSizeChange() {},
    handleCurrentChange() {},
    sub() {
      if (this.form.sort == "" || this.form.sort == null || this.form.sort == undefined) {
        this.form.sort = 0;
      }
      let url = "";
      let str = "";
      if (this.type == "new") {
        url = "/gu/insert_gu_department";
        str = "添加成功";
      } else {
        url = "/gu/update_gu_department";
        str = "修改成功";
        this.form.id = this.item.id;
      }
      console.log(this.form);
      if (this.form.name == "") {
        this.$message({
          message: "请填写科室",
          type: "error",
        });
        return;
      }
      if (this.form.pic == "") {
        this.$message({
          message: "请上传科室图片",
          type: "error",
        });
        return;
      }
      this.axios
        .post(url, this.qs.stringify(this.form))
        .then((rs) => {
          if (rs.data.code == 0) {
            this.dialogFormVisible = false;
            this.form = {};
            this.$message({
              message: str,
              type: "success",
            });
            this.reload();
          } else {
            this.$message(rs.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    query() {
      this.list = [];
      this.axios
        .get("/gu/get_gu_department_like_name?dp_name=" + this.search)
        .then((res) => {
          if (res.data.code == 0) {
            this.list = res.data.result;
          } else {
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchclear() {
      this.getdata();
    },
  },
};
</script>
<style>
.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}

.hdtc {
  width: 80%;
}
</style>
